import React from 'react';
import '../App.css';
import './CardSlide.css';

import SuccessReviewText from '../assets/images/success_review_text.png';

import money from '../assets/images/money_take (1).png';
import money2 from '../assets/images/money_take (2).png';
import money3 from '../assets/images/money_take (3).png';
import money4 from '../assets/images/money_take (4).png';
import money5 from '../assets/images/money_take (5).png';
import money6 from '../assets/images/money_take (6).png';
import money7 from '../assets/images/money_take (7).png';
import money8 from '../assets/images/money_take (8).png';
import money9 from '../assets/images/money_take (9).png';
import money10 from '../assets/images/money_take (10).png';
import money11 from '../assets/images/money_take (11).png';
import money12 from '../assets/images/money_take (12).png';

import slidereview1 from '../assets/images/slidereview (1).png';
import slidereview2 from '../assets/images/slidereview (2).png';
import slidereview3 from '../assets/images/slidereview (3).png';
import slidereview4 from '../assets/images/slidereview (4).png'; 
import slidereview5 from '../assets/images/slidereview (5).png';

import slidereview_real1 from '../assets/images/slideview_real (1).png';
import slidereview_real2 from '../assets/images/slideview_real (2).png';
import slidereview_real3 from '../assets/images/slideview_real (3).png';
import slidereview_real4 from '../assets/images/slideview_real (4).png';
import slidereview_real5 from '../assets/images/slideview_real (5).png';
import slidereview_real6 from '../assets/images/slideview_real (6).png';
import slidereview_real7 from '../assets/images/slideview_real (7).png';
import slidereview_real8 from '../assets/images/slideview_real (8).png';
import slidereview_real9 from '../assets/images/slideview_real (9).png';
import slidereview_real10 from '../assets/images/slideview_real (10).png';
import slidereview_real11 from '../assets/images/slideview_real (11).png';
import slidereview_real12 from '../assets/images/slideview_real (12).png';


function CardSlide() {
  return (
    <div className="card-container">
      <img className='success-review-text' src={SuccessReviewText} alt="Success Review Text" />
      <div className="logos">
        <div className="logos-slide">
          <div>
            <div class="column">
              <img className='money' src={money} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real1} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money2} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real2} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money3} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real3} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money4} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real4} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money5} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real5} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money6} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real6} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money7} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real7} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money8} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real8} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money9} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real9} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money10} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real10} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money11} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real11} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money12} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real12} alt="Slide 1" />
            </div>
          </div>
        </div>
        <div className="logos-slide">
          <div>
            <div class="column">
              <img className='money' src={money} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real1} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money2} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real2} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money3} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real3} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money4} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real4} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money5} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real5} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money6} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real6} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money7} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real7} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money8} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real8} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money9} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real9} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money10} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real10} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money11} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real11} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money12} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real12} alt="Slide 1" />
            </div>
          </div>
        </div>
        <div className="logos-slide">
          <div>
            <div class="column">
              <img className='money' src={money} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real1} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money2} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real2} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money3} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real3} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money4} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real4} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money5} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real5} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money6} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real6} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money7} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real7} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money8} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real8} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money9} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real9} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money10} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real10} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money11} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real11} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money12} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real12} alt="Slide 1" />
            </div>
          </div>
        </div>
        <div className="logos-slide">
          <div>
            <div class="column">
              <img className='money' src={money} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real1} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money2} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real2} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money3} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real3} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money4} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real4} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money5} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real5} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money6} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real6} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money7} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real7} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money8} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real8} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money9} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real9} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money10} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real10} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money11} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real11} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money12} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real12} alt="Slide 1" />
            </div>
          </div>
        </div>
        <div className="logos-slide">
          <div>
            <div class="column">
              <img className='money' src={money} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real1} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money2} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real2} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money3} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real3} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money4} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real4} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money5} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real5} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money6} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real6} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money7} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real7} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money8} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real8} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money9} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real9} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money10} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real10} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money11} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real11} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money12} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real12} alt="Slide 1" />
            </div>
          </div>
        </div>
        <div className="logos-slide">
          <div>
            <div class="column">
              <img className='money' src={money} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real1} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money2} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real2} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money3} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real3} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money4} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real4} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money5} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real5} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money6} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real6} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money7} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real7} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money8} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real8} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money9} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real9} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money10} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real10} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money11} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real11} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money12} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real12} alt="Slide 1" />
            </div>
          </div>
        </div>
        <div className="logos-slide">
          <div>
            <div class="column">
              <img className='money' src={money} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real1} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money2} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real2} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money3} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real3} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money4} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real4} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money5} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real5} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money6} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real6} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money7} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real7} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money8} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real8} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money9} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real9} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money10} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real10} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money11} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real11} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money12} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real12} alt="Slide 1" />
            </div>
          </div>
        </div>
        <div className="logos-slide">
          <div>
            <div class="column">
              <img className='money' src={money} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real1} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money2} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real2} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money3} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real3} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money4} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real4} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money5} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real5} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money6} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real6} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money7} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real7} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money8} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real8} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money9} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real9} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money10} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real10} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money11} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real11} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money12} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real12} alt="Slide 1" />
            </div>
          </div>
        </div>
        <div className="logos-slide">
          <div>
            <div class="column">
              <img className='money' src={money} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real1} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money2} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real2} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money3} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real3} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money4} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real4} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money5} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real5} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money6} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real6} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money7} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real7} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money8} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real8} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money9} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real9} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money10} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real10} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money11} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real11} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money12} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real12} alt="Slide 1" />
            </div>
          </div>
        </div>
        <div className="logos-slide">
          <div>
            <div class="column">
              <img className='money' src={money} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real1} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money2} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real2} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money3} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real3} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money4} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real4} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money5} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real5} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money6} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real6} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money7} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real7} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money8} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real8} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money9} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real9} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money10} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real10} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money11} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real11} alt="Slide 1" />
            </div>
          </div>
          <div>
            <div class="column">
              <img className='money' src={money12} alt="Slide 1" />
            </div>
            <div class="column">
              <img className='hi' src={slidereview_real12} alt="Slide 1" />
            </div>
          </div>
        </div>
       
      </div>
    </div>
    // <div className="card-container">
    //   <img className='success-review-text' src={SuccessReviewText} alt="Success Review Text" />
    //   <div className="logos">
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //     <div className="logos-slide">
    //       <img className='hi' src={slidereview1} alt="Slide 1" />
    //       <img className='hi' src={slidereview2} alt="Slide 2" />
    //       <img className='hi' src={slidereview3} alt="Slide 3" />
    //       <img className='hi' src={slidereview4} alt="Slide 4" />
    //       <img className='hi' src={slidereview5} alt="Slide 5" />
    //     </div>
    //   </div>
    // </div>
  );
}

export default CardSlide;