import React from 'react';
import '../App.css';
import './Final.css';

import final from '../assets/images/final.PNG';

import Oganlogo1 from '../assets/images/oganlogo (1).jpg';

function Final() {
  return (
    <div className="final-container">
      <img className='final-image' src={final} alt="Slide 1" />
    </div>
  );
}

export default Final;