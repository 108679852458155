import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';
import Footer from '../Footer';
import Consultation from '../Consultation';
import VideoScroll from '../VideoScroll';
import Services from '../Services';
import Solution from '../Solution';
import CardSlide from '../CardSlide';
import Backup from '../Backup';
import Organ from '../Organ';
import Process from '../Process';
import FastConsult from '../FastConsult';
import ComparePrice from '../ComparePrice';
import Final from '../Final';


function Home() {
  return (
    <>
      <HeroSection />
      {/* <Cards /> */}
      <Services />
      <Solution />
      <VideoScroll />
      <Backup />
      <Process />
      <CardSlide />
      {/* <Consultation /> */}
      <ComparePrice />
      {/* <FastConsult /> */}
      <Final />
      <Organ />
    </>
  );
}

export default Home;