import React from 'react';
import './Backup.css';

import works from '../assets/images/works.PNG';

import worksM1 from '../assets/images/works_m (4).png';
import worksM2 from '../assets/images/works_m (5).png';
import worksM3 from '../assets/images/works_m (6).png';

import Building_2 from '../assets/images/building_2.jpg';
import Changup from '../assets/images/changup.jpg';
import Save_money from '../assets/images/save_money.jpg';
import Meeting from '../assets/images/meeting.jpg';

import { motion } from "framer-motion"

function Backup() {

  // const isPc = useMediaQuery({
  //   query: "(min-width:1024px)"
  // });
  // const isTablet = useMediaQuery({
  //   query: "(min-width:768px) and (max-width:1023px)"
  // });
  // const isMobile = useMediaQuery({
  //   query: "(max-width:767px)"
  // });


  return (
    <div className='backup'>
      <div className='backup_text_container'>
        <p style={{
          color: 'grey',
          whiteSpace: 'normal',
          marginBottom: '5px',
        }}>EMPARTNERS</p>
        <p className='backup_big_title'>지원서비스</p>
      </div>

      {/* <img className='works' src={works} alt="Logo" /> */}


      <div className='backup_img_group'>
        <motion.div className='backup_img_container'
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 0.5,
            y: { duration: 0.5 },
          }}>
          <img className='works_img' src={worksM1} alt="Logo" />
        </motion.div>
          <motion.div className='backup_img_container'
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{
              ease: "easeInOut",
              duration: 0.9,
              y: { duration: 0.9 },
            }}>          <img className='works_img' src={worksM3} alt="Logo" />
          </motion.div>
        <motion.div className='backup_img_container'
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 0.7,
            y: { duration: 0.7 },
          }}>          <img className='works_img' src={worksM2} alt="Logo" />
        </motion.div>
      </div>

      {/* <div className='backup_img_group'>
        <motion.div className='backup_img_container'
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 0.5,
            y: { duration: 0.5 },
          }}>
          <img className='backup_img' src={Building_2} alt="Logo" />
          <p className='backup_title'>정책자금</p>
          <p className='backup_content'>정부가 지원하는 자금으로</p>
          <p className='backup_content'> 기업의 연구개발, 시설 투자 등을 위한 융자나</p>
          <p className='backup_content'>보조금 형태로 제공됩니다.</p>
        </motion.div>
        <motion.div className='backup_img_container'
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 0.7,
            y: { duration: 0.7 },
          }}>          <img className='backup_img' src={Changup} alt="Logo" />
          <p className='backup_title'>지원사업</p>
          <p className='backup_content'>기술 개발, 인력 양성 등을 위한 </p>
          <p className='backup_content'>정부 지원 프로그램으로, 다양한 보조금과</p>
          <p className='backup_content'> 자금 지원을 받을 수 있습니다.</p>
        </motion.div>
        <motion.div className='backup_img_container'
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 0.9,
            y: { duration: 0.9 },
          }}>          <img className='backup_img' src={Meeting} alt="Logo" />
          <p className='backup_title'>기업인증</p>
          <p className='backup_content'>연구개발 역량을 </p>
          <p className='backup_content'> 인정받은 기업에 부여되는 인증으로, </p>
          <p className='backup_content'>기술력과 신뢰도를 높일 수 있습니다.</p>
        </motion.div>
        <motion.div className='backup_img_container'
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{
            ease: "easeInOut",
            duration: 1.1,
            y: { duration: 1.1 },
          }}>          <img className='backup_img' src={Save_money} alt="Logo" />
          <p className='backup_title'>세금절세</p>
          <p className='backup_content'>연구개발비에 대한 </p>
          <p className='backup_content'>세액 공제 혜택을 통해 기업의 세금 부담을</p>
          <p className='backup_content'>줄일 수 있는 제도입니다.</p>
        </motion.div>
      </div> */}
    </div >
  );
}

export default Backup;