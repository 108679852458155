import React, { useState, useEffect } from 'react';
import './Products.css';
// import Phone_frame from '../../assets/images/cell-phone.png';
import Phone_frame from '../../assets/images/cell_1.png';
import Saup from '../../assets/images/saup.png';
import Logo from '../../assets/images/blue_logo_text.png';
import Gogek from '../../assets/images/gogek.png';
import Notice from '../../assets/images/price_notice.png';
import Percent from '../../assets/images/percent.png';
import Contract from '../../assets/images/contract.png';
import ComparePrice from '../../assets/images/comparePriace_mobile.jpg';
// import Phone_frame from '../../assets/images/cell-phone.png';
// import Phone_frame from '../../assets/images/phone_frame_real.png';
import Modal from '../../components/Modal';

const access_token = "xIrD1S8AJDZa+GGSxA29yTMa7wjMKJn7L5AeHdN7xGeIj448yxsQ34Lwsr4pAJffuyUTkgO6cD7ERCKycuCc83RbdYvlqQFhB8lE9nm27feSd14U4jbfVg=="

export default function Products() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);
  const [isModalOpenPrivate, setIsModalOpenPrivate] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false); // 개인정보 동의 체크 상태

  // const client_id = process.env.REACT_APP_KAKAO_REST_API_KEY;
  // const grant_type = "authorization_code";
  // const PARAMS = new URL(document.location).searchParams;
  // const KAKAO_CODE = PARAMS.get("code"); //인가코드

  // const url = `https://kauth.kakao.com/oauth/token?grant_type=${grant_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&code=${KAKAO_CODE}`;

  // //access token 받아오기
  // const getAccessToken = async () => {
  //   console.log("getAccessToken 호출");
  //   try {
  //     const response = await axios
  //       .post(url, {
  //         headers: { "Content-type": "application/x-www-form-urlencoded" },
  //       })
  //       .then((res) => {
  //         console.log(res);
  //         const accessToken = res.data.access_token;
  //         console.log(accessToken);
  //         axios
  //           .get(`https://kapi.kakao.com/v2/user/me`, {
  //             headers: {
  //               Authorization: `Bearer ${accessToken}`,
  //             },
  //           })
  //           .then((res) => {
  //             console.log(res);
  //             navigate("/talkabout");
  //           });
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // //인가 코드가 들어왔을 때만 실행
  // useEffect(() => {
  //   getAccessToken();
  // }, [KAKAO_CODE]);

  const openContractModal = () => {
    setIsContractModalOpen(true);
  };

  const closeContractModal = () => {
    setIsContractModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModalPrivate = () => {
    setIsModalOpenPrivate(true);
  };

  const closeModalPrivate = () => {
    setIsModalOpenPrivate(false);
  };


  const getAccessToken = async () => {
    const loginUrl = 'https://docs.esignon.net/api/testapi2/login'; // 실제 companyId로 대체하세요

    const loginData = {
      "header": {
        "request_code": "1001Q"
      },
      "body": {
        "memb_email": "empartners77@gmail.com",
        "memb_pwd": "bnm36051212!"
      }
    };

    return fetch(loginUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const accessToken = data.body.access_token;
        console.log('Access Token:', accessToken);  // 인증 토큰을 출력
        return accessToken;  // 토큰 반환
      })
      .catch((error) => {
        console.error('Fetch error:', error.message);
      });
  }

  // esignon API TEST
  const fetchEsignOn = (email, name) => {
    getAccessToken().then(accessToken => {
      const url = "https://docs.esignon.net/api/testapi2/startsimple"
      const data = {
        "header": {
          "request_code": "5005Q",
          "version": "9.9.99"
        },
        "body": {
          "biz_id": "0",
          "workflow_name": "EM파트너스_경영지도_자문계약서_기본양식원본_1",
          "memb_email": "empartners77@gmail.com",
          "doc_id": "2",
          "language": "ko-KR",
          "player_list": [{
            "field_owner": "1",
            "email": `${email}`,
            "name": `${name}`
          }]
        }
      }

      console.log("다다다: ", accessToken)

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `esignon ${accessToken}` // 발급받은 토큰을 동적으로 추가
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('Request Success:', data);
          alert("계약서 발송")
        })
        .catch((error) => {
          console.error('Request Error:', error.message);
        });
    });
  }



  // const apiUrl = `https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=h0JqQNiGsNhQ7IGi3XAxokWCyUM9hJqBor3tLuFhqQ%2FdXp%2BnEFkiTPD%2BVNtDjD4hFHxriYQWtXIdZANlFkCxng%3D%3D`;
  const apiUrl = `https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=h0JqQNiGsNhQ7IGi3XAxokWCyUM9hJqBor3tLuFhqQ%2FdXp%2BnEFkiTPD%2BVNtDjD4hFHxriYQWtXIdZANlFkCxng%3D%3D`;
  // const apiUrl = `https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=${process.env.REACT_APP_API_SECRET_KEY}`;
  const mondyUrl = ''

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [checkBusinessNum, setCheckBusinessNum] = useState('fail')

  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    business: '',
    email: '',
    businessNumber: '',
    reportDate: '',
    location: '',
    annualSales: '',
    creditScore: '',
    taxStatus: '',
    policyExperience: '',
    employmentSize: '',
    certification: '',
  });

  const [formContractData, setContractFormData] = useState({
    email: '',
    name: '',
  });

  const handleConsentChange = (e) => {
    setConsentChecked(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!consentChecked) {
      alert('개인정보 수집 및 이용에 동의해야 합니다.');
      return;
    }

    if (currentPage === 6 && !formData.business) {
      alert("업종을 입력해주세요.");
      return;
    }

    if (currentPage === 6) {
      // 마지막 페이지에서 로딩 화면으로 전환
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setCurrentPage(currentPage + 1); // 10번째 페이지로 이동 (AI 진단 결과 페이지)

        // 여기 AI 진단 코드 추가!!! ----------------
        //   console.log(JSON.stringify(formData));


      }, 5000); // 5초 로딩 후 결과 페이지로 이동
    }

    const {
      name,
      contact,
      location,
      business,
      annualSales,
      email,
      businessNumber,
      reportDate,
      creditScore,
      taxStatus,
      policyExperience,
      employmentSize,
      certification,
    } = formData;

    // if (!name || !contact || !business || !email) {
    //   let message = '다음 항목을 입력해 주세요:\n';
    //   if (!name) message += '- 이름\n';
    //   if (!contact) message += '- 연락처\n';
    //   if (!business) message += '- 업종/업태\n';
    //   if (!email) message += '- 이메일\n';
    //   alert(message);
    //   return;
    // }

    const apiToken = process.env.REACT_APP_API_MONDAY_KEY;
    const boardId = '1928672385'; // 실제 Monday.com 보드 ID를 입력하세요
    const currentDate = new Date().toISOString(); // 현재 날짜와 시간을 ISO 8601 형식으로 변환

    const query = `
      mutation {
        create_item (
          board_id: ${boardId}, 
          item_name: "${name}", 
          column_values: "${JSON.stringify({
      status: { label: "상담 전" }, // 상태 컬럼 (상담 전 상태로 설정)
      text5: contact, // 연락처 컬럼
      text: currentDate, // 타임스탬프
      text3: location, // 지역 컬럼
      text1: business, // 업종 / 업태 컬럼
      text9: annualSales, // 연매출 컬럼
      text4: email, // 이메일주소 컬럼
      color: { label: "홈페이지" }, // 경로
    }).replace(/"/g, '\\"')}"
        ) {
          id
        }
      }
    `;

    fetch('https://api.monday.com/v2', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': apiToken,
      },
      body: JSON.stringify({ query }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          console.error('API Errors:', data.errors);
          alert('데이터 전송 중 오류가 발생했습니다.');
        } else {
          const parentId = data.data.create_item.id;

          const subItems = [
            { name: "사업자번호", value: businessNumber },
            { name: "사업자신고일", value: reportDate },
            { name: "대표자 신용점수", value: creditScore },
            { name: "국세 / 지방세 현재 기준 체납 여부", value: taxStatus },  // 추가 항목
            { name: "정책자금 수혜 경험 여부", value: policyExperience },
            { name: "4대보험 기준 고용인원", value: employmentSize },
            { name: "보유하신 인증 항목 (기업부설, 특허, 벤처인증 등등)", value: certification },
          ];



          subItems.forEach(subItem => {
            const columnValues = JSON.stringify({
              long_text: subItem.value // 텍스트 컬럼에 값을 설정
            });

            const subItemQuery = `
              mutation {
                create_subitem (
                  parent_item_id: ${parentId}, 
                  item_name: "${subItem.name}", 
                  column_values: "${columnValues.replace(/"/g, '\\"')}"
                ) {
                  id
                }
              }
            `;

            console.log('Sub Item Query:', subItemQuery); // 쿼리 확인용 로그

            // fetch('https://api.monday.com/v2', {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //     'Authorization': apiToken,
            //   },
            //   body: JSON.stringify({ query: subItemQuery }),
            // })
            //   .then(subItemResponse => subItemResponse.json())
            //   .then(subItemData => {
            //     if (subItemData.errors) {
            //       console.error('API Errors:', subItemData.errors);
            //       alert(`하위 아이템 ${subItem.name} 생성 중 오류가 발생했습니다.`);
            //     } else {
            //       console.log(`하위 아이템 ${subItem.name} 생성 완료`, subItemData);
            //     }
            //   })
            //   .catch(error => {
            //     console.error('Error:', error);
            //     alert(`하위 아이템 ${subItem.name} 생성 중 오류가 발생했습니다.`);
            //   });
          });

          alert('상담 신청이 완료되었습니다.');
          closeModal();
          console.log('Parent Item and Sub Items created successfully');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('데이터 전송 중 오류가 발생했습니다.');
      });
  };


  const handleContractSubmit = (e) => {
    e.preventDefault();

    const
      {
        email,
        name
      } = formContractData;

    if (!name || !email) {
      let message = '다음 항목을 입력해 주세요:\n';
      if (!name) message += '- 이름\n';
      if (!email) message += '- 이메일\n';
      alert(message);
      return;
    }

    if (!consentChecked) {
      alert('개인정보 수집 및 이용에 동의해야 합니다.');
      return;
    }

    fetchEsignOn(email, name)
  };

  const goToNextPage = () => {
    if (currentPage === 1 && !formData.businessNumber) {
      alert("사업자번호를 입력해주세요.");
      return;
    }
    if (currentPage === 3 && !formData.annualSales) {
      alert("연 매출액을 입력해주세요.");
      return;
    }
    if (currentPage === 4 && !formData.name) {
      alert("이름을 입력해주세요.");
      return;
    }
    if (currentPage === 5 && !formData.contact) {
      alert("전화번호를 입력해주세요.");
      return;
    }

    if (currentPage < 6 && checkBusinessNum === 'success') {
      setCurrentPage(currentPage + 1);
    } else if (currentPage === 7) {
      // 마지막 페이지에서 로딩 화면으로 전환
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setCurrentPage(currentPage + 1); // 10번째 페이지로 이동 (AI 진단 결과 페이지)

        // 여기 AI 진단 코드 추가!!! ----------------
        //   console.log(JSON.stringify(formData));


      }, 5000); // 5초 로딩 후 결과 페이지로 이동
    }
    setCurrentPage(currentPage + 1)
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToFirstPage = () => {
    console.log(JSON.stringify(formData));
    setCurrentPage(1);
    setFormData({
      name: '',
      contact: '',
      business: '',
      email: '',
      businessNumber: '',
      reportDate: '',
      location: '',
      annualSales: '',
      creditScore: '',
      taxStatus: '',
      policyExperience: '',
      employmentSize: '',
      certification: '',
    });
  };

  const goToHomePage = () => {
    window.location.href = '/';
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOptionSelect = (field, value) => {
    setFormData({ ...formData, [field]: value });
    goToNextPage();
  };

  const handleContractInputChange = (e) => {
    const { name, value } = e.target;
    setContractFormData({ ...formContractData, [name]: value });
  };

  const TcorptChk = () => {
    goToNextPage()
  }

  const corpChk = () => {
    const regNum = formData.businessNumber.replace(/[^0-9]/g, '');

    if (!regNum) {
      alert("사업자등록번호를 입력해주세요.");
      return;
    }

    const data = {
      "b_no": [regNum]
    };
    // goToNextPage()

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Accept": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(result => {
        setLoading(false);
        if (result.match_cnt === 1) {
          // 성공 시 AI 진단 결과 페이지로 바로 이동
          console.log("success: ", result);
          setCheckBusinessNum("success");
          goToNextPage()
        } else {
          // 실패
          console.log("fail");
          setCheckBusinessNum("fail");
          alert(result.data[0]["tax_type"]);
        }
      })
      .catch(error => {
        console.log("error", error);
        alert("서버와의 통신 중 오류가 발생했습니다.");
      });
  };

  const saveData = () => {
    console.log(JSON.stringify(formData));
  };


  const sendContract = () => {
    console.log(JSON.stringify(formData));
  };


  return (
    <div className='main-container'>
      {/* <img className='phone-frame-detail' src={Phone_frame} alt="Phone Frame" /> */}
      <div className='phone-frame'>
        <div className='component'>
          {loading ? (
            <>
              <h2 className='h2-tag'>입력된 정보를 바탕으로 정책자금을 찾는 중이에요</h2>
              <div className="loading-icon">
                {/* <img src="/path_to_loading_image.png" alt="Loading" /> */}
                <div className="loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </>
          ) : currentPage === 1 ? (
            <>
              {/* <h2 className='h2-tag'>사업자등록번호를 입력해 주세요</h2> */}
              <div className='saup_container'>
                <img className="saup" src={Saup} />
              </div>
              <input
                type="text"
                name="businessNumber"
                value={formData.businessNumber}
                onChange={handleInputChange}
                placeholder="사업자등록번호를 입력하세요"
              />
              <div className="button-group">
                <div className="button" onClick={corpChk}>
                  {/* <div className="button" onClick={goToNextPage}> */}
                  다음
                </div>
              </div>
            </>
          )
            // : currentPage === 2 ? (
            //   <>
            //     <h2 className='h2-tag'>사업자 신고일을 입력해 주세요</h2>
            //     <input
            //       type="date"
            //       name="reportDate"
            //       value={formData.reportDate}
            //       onChange={handleInputChange}
            //     />
            //     <div className="button-group">
            //       <div className="button-back" onClick={goToPreviousPage}>
            //         뒤로
            //       </div>
            //       <div className="button-next" onClick={goToNextPage}>
            //         다음
            //       </div>
            //     </div>
            //   </>
            // ) 
            : currentPage === 2 ? (
              <>
                <h2 className='h2-tag'>사업장 위치를 선택해 주세요</h2>
                <div className="grid-options">
                  {['서울', '경기', '인천', '강원', '충북', '충남', '대전', '세종', '경북', '경남', '대구', '울산', '부산', '전북', '전남', '광주', '제주'].map((location) => (
                    <div key={location} className="option" onClick={() => handleOptionSelect('location', location)}>
                      {location}
                    </div>
                  ))}
                </div>
                <div className="button-group">
                  <div className="button" onClick={goToPreviousPage}>
                    뒤로
                  </div>
                </div>
              </>
            ) : currentPage === 3 ? (
              <>
                <h2 className='h2-tag'>연 매출액을 입력해 주세요</h2>
                <input
                  type="number"
                  name="annualSales"
                  value={formData.annualSales}
                  onChange={handleInputChange}
                  placeholder="매출액을 입력하세요 (단위: 만원)"
                />
                <div className="button-group">
                  <div className="button-back" onClick={goToPreviousPage}>
                    뒤로
                  </div>
                  <div className="button-next" onClick={goToNextPage}>
                    다음
                  </div>
                </div>
              </>
            ) : currentPage === 4 ? (
              <>
                <h2 className='h2-tag'>이름을 입력해주세요.</h2>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="이름을 입력해주세요."
                />
                <div className="button-group">
                  <div className="button-back" onClick={goToPreviousPage}>
                    뒤로
                  </div>
                  <div className="button-next" onClick={goToNextPage}>
                    다음
                  </div>
                </div>
              </>
            ) : currentPage === 5 ? (
              <>
                <h2 className='h2-tag'>전화번호를 입력해주세요.</h2>
                <input
                  type="text"
                  name="contact"
                  value={formData.contact}
                  onChange={handleInputChange}
                  placeholder="전화번호를 입력해주세요."
                />
                <div className="button-group">
                  <div className="button-back" onClick={goToPreviousPage}>
                    뒤로
                  </div>
                  <div className="button-next" onClick={goToNextPage}>
                    다음
                  </div>
                </div>
              </>
            ) : currentPage === 6 ? (
              <>
                <h2 className='h2-tag'>업종을 입력해주세요.</h2>
                <input
                  type="text"
                  name="business"
                  value={formData.business}
                  onChange={handleInputChange}
                  placeholder="업종을 입력해주세요."
                />
                <div className="customCheckboxGroup">
                  <input
                    type="checkbox"
                    id="consent"
                    name="consent"
                    checked={consentChecked}
                    onChange={handleConsentChange}
                  />
                  <label htmlFor="consent"><div className='custom-private-modal-text' onClick={openModalPrivate}>개인정보</div> 수집 및 이용에 동의</label>
                </div>
                <div className="button-group">
                  <div className="button-back" onClick={goToPreviousPage}>
                    뒤로
                  </div>
                  <div className="button-next" onClick={handleSubmit}>
                    상담신청
                  </div>
                </div>

              </>
            )
              // : currentPage === 5 ? (
              //   <>
              //     <h2 className='h2-tag'>대표자의 신용점수를 선택해 주세요</h2>
              //     <div className="grid-options">
              //       <div className="option" onClick={() => handleOptionSelect('creditScore', '700점 이상')}>
              //         700점 이상
              //       </div>
              //       <div className="option" onClick={() => handleOptionSelect('creditScore', '700점 이하')}>
              //         700점 이하
              //       </div>
              //     </div>
              //     <div className="button-group">
              //       <div className="button" onClick={goToPreviousPage}>
              //         뒤로
              //       </div>
              //     </div>
              //   </>
              // ) 
              // : currentPage === 6 ? (
              //   <>
              //     <h2 className='h2-tag'>국세/지방세 현재 기준 체납 여부를 선택해 주세요</h2>
              //     <div className="grid-options">
              //       <div className="option" onClick={() => handleOptionSelect('taxStatus', 'O')}>
              //         O
              //       </div>
              //       <div className="option" onClick={() => handleOptionSelect('taxStatus', 'X')}>
              //         X
              //       </div>
              //     </div>
              //     <div className="button-group">
              //       <div className="button" onClick={goToPreviousPage}>
              //         뒤로
              //       </div>
              //     </div>
              //   </>
              // ) 
              // : currentPage === 7 ? (
              //   <>
              //     <h2 className='h2-tag'>정책자금 수혜 경험 여부를 선택해 주세요</h2>
              //     <div className="grid-options">
              //       <div className="option" onClick={() => handleOptionSelect('policyExperience', 'O')}>
              //         O
              //       </div>
              //       <div className="option" onClick={() => handleOptionSelect('policyExperience', 'X')}>
              //         X
              //       </div>
              //     </div>
              //     <div className="button-group">
              //       <div className="button" onClick={goToPreviousPage}>
              //         뒤로
              //       </div>
              //     </div>
              //   </>
              // ) 
              // : currentPage === 8 ? (
              //   <>
              //     <h2 className='h2-tag'>4대보험 기준 고용인원을 선택해 주세요</h2>
              //     <div className="grid-options">
              //       <div className="option" onClick={() => handleOptionSelect('employmentSize', '5인 이상')}>
              //         5인 이상
              //       </div>
              //       <div className="option" onClick={() => handleOptionSelect('employmentSize', '5인 미만')}>
              //         5인 미만
              //       </div>
              //     </div>
              //     <div className="button-group">
              //       <div className="button" onClick={goToPreviousPage}>
              //         뒤로
              //       </div>
              //     </div>
              //   </>
              // ) 
              // : currentPage === 9 ? (
              //   <>
              //     <h2 className='h2-tag'>보유하신 인증 항목을 선택해 주세요</h2>
              //     <div className="grid-options">
              //       {['벤처기업', '이노비즈', '기업부설연구소', '메인비즈', '특허', '없음'].map((certification) => (
              //         <div key={certification} className="option" onClick={() => handleOptionSelect('certification', certification)}>
              //           {certification}
              //         </div>
              //       ))}
              //     </div>
              //     <div className="button-group">
              //       <div className="button" onClick={goToPreviousPage}>
              //         뒤로
              //       </div>
              //       {/* <div className="button" onClick={goToNextPage}>
              //       AI 진단 시작
              //     </div> */}
              //     </div>
              //   </>
              // ) 
              : (
                <>
                  {/* <h2 className='h2-tag'>고객님의 AI 진단 결과입니다</h2> */}
                  {/* <img className="saup" src={Gogek} alt="Phone Frame" /> */}
                  {/* <img className="notice" src={Notice} alt="Phone Frame" /> */}
                  <div className="result-container">
                    <img className="notice" src={Notice} alt="Phone Frame" />
                  </div>
                  {/* <div className="button" onClick={fetchEsignOn}> */}
                  {/*<div className="button" onClick={openContractModal}>
                지금 계약하기
              </div> */}
                  {/* <div className="button-big" onClick={openModal}>
                    무료 정책자금 상담신청
                  </div> */}
                  <div className="button-group">
                    <div className="button-back" onClick={goToFirstPage}>
                      처음으로
                    </div>
                    <div className="button-next" onClick={goToHomePage}>
                      홈페이지로
                    </div>
                  </div>
                </>
              )}
        </div>
      </div>
      {
        isContractModalOpen && (
          <div id="customModal" className="customModal" onClick={closeContractModal}>
            <div className="customModalContent" onClick={(e) => e.stopPropagation()}>
              <span className="customClose" onClick={closeContractModal}>&times;</span>
              <h2 className='h2-tag'>계약서 작성 양식</h2>
              <img className="notice_contract" src={ComparePrice} alt="Phone Frame" />
              {/* <img className="notice_contract" src={Contract} alt="Phone Frame" /> */}
              <p className='p_tag'>해당 이메일로 계약서가 발송됩니다.</p>
              <form className="customForm" onSubmit={handleContractSubmit}>
                <label htmlFor="name" className="customLabel">이름</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="이름을 입력해 주세요"
                  className="customInputText"
                  value={formContractData.name}
                  onChange={handleContractInputChange}
                />
                <label htmlFor="email" className="customLabel">이메일</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="이메일을 입력해 주세요"
                  className="customInputText"
                  value={formContractData.email}
                  onChange={handleContractInputChange}
                />
                <div className="customCheckboxGroup">
                  <input
                    type="checkbox"
                    id="consent"
                    name="consent"
                    checked={consentChecked}
                    onChange={handleConsentChange}
                  />
                  <label htmlFor="consent"><div className='custom-private-modal-text' onClick={openModalPrivate}>개인정보</div> 수집 및 이용에 동의합니다.</label>
                </div>
                <button type="" className="customSubmitButton" onClick={fetchEsignOn}>상담신청</button>
              </form>
            </div>
          </div>
        )
      }
      {
        isModalOpen && (
          <div id="customModal" className="customModal" onClick={closeModal}>
            <div className="customModalContent" onClick={(e) => e.stopPropagation()}>
              <span className="customClose" onClick={closeModal}>&times;</span>
              <h2 className='h2-tag'>정책자금 상담신청</h2>
              <p className='p_tag'>가장 합리적이게 자금이 지원되도록 정책자금을 빠르고 신속하게 찾아드립니다.</p>
              <form className="customForm" onSubmit={handleSubmit}>
                <label htmlFor="name" className="customLabel">이름</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="이름을 입력해 주세요"
                  className="customInputText"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                <label htmlFor="contact" className="customLabel">연락처</label>
                <input
                  type="text"
                  id="contact"
                  name="contact"
                  placeholder="'-'를 제외한 숫자만 입력해 주세요"
                  className="customInputText"
                  value={formData.contact}
                  onChange={handleInputChange}
                />
                <label htmlFor="business" className="customLabel">업종/업태</label>
                <input
                  type="text"
                  id="business"
                  name="business"
                  placeholder="업종/업태를 입력해 주세요"
                  className="customInputText"
                  value={formData.business}
                  onChange={handleInputChange}
                />
                <label htmlFor="email" className="customLabel">이메일</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="이메일을 입력해 주세요"
                  className="customInputText"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <div className="customCheckboxGroup">
                  <input
                    type="checkbox"
                    id="consent"
                    name="consent"
                    checked={consentChecked}
                    onChange={handleConsentChange}
                  />
                  <label htmlFor="consent"><div className='custom-private-modal-text' onClick={openModalPrivate}>개인정보</div> 수집 및 이용에 동의합니다.</label>
                </div>
                <button type="submit" className="customSubmitButton">상담신청</button>
              </form>
            </div>
          </div>
        )
      }
      {isModalOpenPrivate && <Modal open={openModalPrivate} close={closeModalPrivate} header="개인정보처리방침" />}
    </div >
  );
}