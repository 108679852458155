// import React from 'react';
// import './Backup.css';

// import Slice1 from '../assets/images/Slide (1).png';
// import Slice2 from '../assets/images/Slide (2).png';
// import Slice3 from '../assets/images/Slide (3).png';
// import Slice4 from '../assets/images/Slide (4).png';
// import Slice5 from '../assets/images/Slide (5).png';
// import Slice6 from '../assets/images/Slide (6).png';
// import Slice7 from '../assets/images/Slide (7).png';
// import Slice8 from '../assets/images/Slide (8).png';


// import process from '../assets/images/process.png';


// import { motion } from "framer-motion"

// function Process() {

//   const isPc = useMediaQuery({
//     query: "(min-width:1024px)"
//   });
//   const isTablet = useMediaQuery({
//     query: "(min-width:768px) and (max-width:1023px)"
//   });
//   const isMobile = useMediaQuery({
//     query: "(max-width:767px)"
//   });


//   return (
//     <div className='process'>
//       <div className='process_text_container'>
//         <p style={{
//           color: 'black',
//           whiteSpace: 'normal',
//           marginBottom: '5px',
//         }}>EMPARTNERS</p>
//         <p className='process-title'>1대1 상담신청프로세스</p>
//       </div>

//       {/* <div className='grid-container'>
//         <img className='process_img' src={Slice1} alt="Slide 1" />
//         <img className='process_img' src={Slice2} alt="Slide 2" />
//         <img className='process_img' src={Slice3} alt="Slide 3" />
//         <img className='process_img' src={Slice4} alt="Slide 4" />
//         <img className='process_img' src={Slice5} alt="Slide 5" />
//         <img className='process_img' src={Slice6} alt="Slide 6" />
//         <img className='process_img' src={Slice7} alt="Slide 7" />
//         <img className='process_img' src={Slice8} alt="Slide 8" />
//       </div> */}

//       <img className='process_img_background' src={process} alt="Slide 1" />


//     </div >
//   );
// }

// export default Process;


import React from 'react';
import './Process.css';

import Slice1 from '../assets/images/Slide (1).png';
import Slice2 from '../assets/images/Slide (2).png';
import Slice3 from '../assets/images/Slide (3).png';
import Slice4 from '../assets/images/Slide (4).png';
import Slice5 from '../assets/images/Slide (5).png';
import Slice6 from '../assets/images/Slide (6).png';
import Slice7 from '../assets/images/Slide (7).png';
import Slice8 from '../assets/images/Slide (8).png';

import Process_m1 from '../assets/images/process_m (1).png';
import Process_m2 from '../assets/images/process_m (2).png';
import Process_m3 from '../assets/images/process_m (3).png';
import Process_m4 from '../assets/images/process_m (4).png';

import { useMediaQuery } from "react-responsive"

import process from '../assets/images/process.png';


import { motion } from "framer-motion"

function Process() {

  const isPc = useMediaQuery({
    query: "(min-width:1024px)"
  });
  const isTablet = useMediaQuery({
    query: "(min-width:768px) and (max-width:1023px)"
  });
  const isMobile = useMediaQuery({
    query: "(max-width:767px)"
  });


  return (
    <div className='process'>
      <div className='backup_text_container'>
        <p style={{
          color: 'grey',
          whiteSpace: 'normal',
          marginBottom: '5px',
        }}>EMPARTNERS</p>
        <p className='process-title'>1대1 상담신청프로세스</p>
      </div>

      {isPc && <img className='process_img_background' src={process} alt="Slide 1" />}

      {!isPc &&
        <>
          <div className='backup_img_group'>
            <motion.div className='backup_img_container'
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false }}
              transition={{
                ease: "easeInOut",
                duration: 0.5,
                y: { duration: 0.5 },
              }}>
              <img className='process_img' src={Process_m1} alt="Logo" />
            </motion.div>
            <motion.div className='backup_img_container'
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false }}
              transition={{
                ease: "easeInOut",
                duration: 0.7,
                y: { duration: 0.7 },
              }}>
              <img className='process_img' src={Process_m4} alt="Logo" />
            </motion.div>

            <motion.div className='backup_img_container'
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false }}
              transition={{
                ease: "easeInOut",
                duration: 0.7,
                y: { duration: 0.7 },
              }}>
              <img className='process_img' src={Process_m3} alt="Logo" />
            </motion.div>
            <motion.div className='backup_img_container'
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false }}
              transition={{
                ease: "easeInOut",
                duration: 0.9,
                y: { duration: 0.9 },
              }}>
              <img className='process_img' src={Process_m2} alt="Logo" />
            </motion.div>
          </div>
        </>}
    </div >
  );
}

export default Process;
