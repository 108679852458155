import React, { useEffect, useRef } from 'react';
import './Solution.css';
import Jungcheck from '../assets/images/jungcheck.jpg';
import SizeFit from '../assets/images/size_fit.png';
import Empartners from '../assets/images/empartners_grey.png';
import Unjeon from '../assets/images/unjeon.jpg';
import Changup from '../assets/images/changup.jpg';

import JungcheckText from '../assets/images/jungcheck_text.png';
import ChangupText from '../assets/images/changup_jagum.png';
import UnjeonText from '../assets/images/unjeon_jagum.png';
import SisulText from '../assets/images/sisu_jagum.png';

import JungcheckTextBlack from '../assets/images/jungcheck_text_black.png';
import JungcheckTextSky from '../assets/images/jungcheck_text_sky.png';
import JungcheckTextSky_2 from '../assets/images/jungcheck_text_sky_2.png';
import SolutioncheckTextSky from '../assets/images/solution_text_sky.png';
import Solution_Text1 from '../assets/images/solution_text (1).png';
import Solution_Text2 from '../assets/images/solution_text (2).png';
import Solution_Text3 from '../assets/images/solution_text (3).png';
import ChangupTextBlack from '../assets/images/changup_jagum_black.png';
import UnjeonTextBlack from '../assets/images/unjeon_jagum_black.png';
import SisulTextBlack from '../assets/images/sisu_jagum_black.png';
import { motion } from "framer-motion"
import { useMediaQuery } from "react-responsive"


function Solution() {

  const isPc = useMediaQuery({
    query: "(min-width:1024px)"
  });
  const isTablet = useMediaQuery({
    query: "(min-width:768px) and (max-width:1023px)"
  });
  const isMobile = useMediaQuery({
    query: "(max-width:767px)"
  });



  return (
    <div className='services'>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}>
        <div className='empartner__container'>
          {/* <img className='empartner' src={Empartners} alt="Logo" /> */}
        </div>
      </motion.div>

      {isPc &&
        <>
          <div className='jungcheck'>
            <img className='solution__text__img' src={SolutioncheckTextSky} alt="Logo" />
            {/* <img className='jungcheck__img' src={SizeFit} alt="Logo" /> */}
          </div>

          <div className='solution_group'>
            <img className='solution_img' src={Solution_Text1} alt="Logo" />
            <img className='solution_img' src={Solution_Text2} alt="Logo" />
            <img className='solution_img' src={Solution_Text3} alt="Logo" />
          </div>
        </>
      }

      {!isPc &&
        <>
          <div className='jungcheck'>
            <img className='solution__text__img' src={SolutioncheckTextSky} alt="Logo" />
            {/* <img className='jungcheck__img' src={SizeFit} alt="Logo" /> */}
          </div>

          <div className='solution_group'>
            <img className='solution_img' src={Solution_Text1} alt="Logo" />
            <img className='solution_img' src={Solution_Text2} alt="Logo" />
            <img className='solution_img' src={Solution_Text3} alt="Logo" />
          </div>
        </>
      }

    </div >
  );
}

export default Solution;