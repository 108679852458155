import React, { useState } from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
// import Services from './components/Services';
import Products from './components/pages/Products';
import SignUp from './components/pages/SignUp';
import Footer from './components/Footer';
import Channel from './components/Channel';
import FastConsult from './components/FastConsult';
import Modal from './components/Modal';
import KakaoLogin from './components/pages/KakaoLogin';


function Layout() {
  const location = useLocation();

  // useState를 사용하여 open 상태를 변경한다. (open일 때 true로 만들어 열리는 방식)
  const [modalOpenUsing, setModalOpenUsing] = useState(false);
  const [modalOpenPrivate, setModalOpenPrivate] = useState(false);
  const [modalFastModal, setFastModal] = useState(false);

  const openFastModal = () => {
    setFastModal(true)
  }

  const closeFastModal = () => {
    setFastModal(false)
  }

  const openModalUsing = () => {
    setModalOpenUsing(true);
  };

  const closeModalUsing = () => {
    setModalOpenUsing(false);
  };

  const openModalPrivate = () => {
    setModalOpenPrivate(true);
  };

  const closeModalPrivate = () => {
    setModalOpenPrivate(false);
  };

  // 특정 경로에 따라 Navbar와 Footer를 숨김
  const hideNavbarFooter = location.pathname === '/slide';
  const hideNavbarFooter2 = location.pathname === '/kakaoLogin';

  return (
    <>
      {(!hideNavbarFooter && !hideNavbarFooter2) && <Navbar />}
      {(!hideNavbarFooter && !hideNavbarFooter2) && <Modal open={modalOpenUsing} close={closeModalUsing} header="이용약관" />}
      {(!hideNavbarFooter && !hideNavbarFooter2) && <Modal open={modalOpenPrivate} close={closeModalPrivate} header="개인정보처리방침" />}
      <Routes>
        <Route path='/' exact element={<Home />} />
        {/* <Route path='/service' element={<Services />} /> */}
        <Route path='/kakaoLogin' element={<KakaoLogin />} />
        <Route path='/slide' element={<Products />} />
        <Route path='/contact' element={<SignUp />} />
      </Routes>
      {(!hideNavbarFooter && !hideNavbarFooter2) && <Footer openModalUsing={openModalUsing} openModalPrivate={openModalPrivate} />}
      {(!hideNavbarFooter && !hideNavbarFooter2) && <Channel openFastModal={openFastModal} />}
      {modalFastModal && <FastConsult closeFastModal={closeFastModal} />}
    </>
  );
}


function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;