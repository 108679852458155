
import React, { useState, useEffect } from 'react';
import './Products.css';
import Saup from '../../assets/images/saup.png';
import KakaoLoginButton from '../../assets/images/kakaoLogin_button.png';
import kakaCotents from '../../assets/images/kakaCotents.png';
import axios from 'axios';

export default function KakaoLogin() {
    const loading = false
    const REACT_APP_KAKAO_REDIREDCT_URI = "https://empartners.co.kr/slide";
    /*home.js*/
    const restAPIKeys = process.env.REACT_APP_KAKAO_REST_API_KEY;
    const redirectURI = REACT_APP_KAKAO_REDIREDCT_URI;
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${restAPIKeys}&redirect_uri=${redirectURI}&response_type=code`;

    const kakaoLogin = () => {
        window.location.href = KAKAO_AUTH_URL;
    };

    return (
        <div className='main-container'>
            {/* <img className='phone-frame-detail' src={Phone_frame} alt="Phone Frame" /> */}
            <div className='phone-frame'>
                <div className='kakaComponent'>
                    <div className='NoneDisplay'>

                    </div>

                    <div className='contents-group'>
                        <img className='kakaoContensImg' src={kakaCotents} alt="Phone Frame" />
                    </div>

                    <div className="kakaoLoginButtonGroup">
                        <div onClick={() => {
                            kakaoLogin();
                        }}>
                            <img className="kakaoLoginButton" src={KakaoLoginButton} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}