import React from 'react';
import './ComparePrice.css';
import Banner from '../assets/images/comparebanner.png';
import Main from '../assets/images/comparePriace.jpg';
import MainMobile from '../assets/images/comparePriace_mobile.PNG';
import { useMediaQuery } from "react-responsive"

function ComparePrice() {

  const isPc = useMediaQuery({
    query: "(min-width:1024px)"
  });
  const isTablet = useMediaQuery({
    query: "(min-width:768px) and (max-width:1023px)"
  });
  const isMobile = useMediaQuery({
    query: "(max-width:767px)"
  });

  return (
    <div className="compareContainer">
      <img className={!isMobile ? 'mainText' : 'mainTextMobile'} src={Banner} alt="Logo" />
      {!isMobile &&
        <img className='mainImage' src={Main} alt="Logo" />
      }
      {isMobile &&
        <img className='mainImage' src={MainMobile} alt="Logo" />
      }
    </div>
  );
}

export default ComparePrice;